<template>
  <el-popover
    placement="top"
    width="400"
    trigger="hover"
  >
    <div class="notice-content">
        <el-row type="flex" justify="center" align="middle">
            <i class="el-icon-warning"></i>
            <div class="notice-content_title">深圳订阅功能升级改造中，暂不支持新增订阅</div>
        </el-row>
      <p>因订阅数据来源更换，深圳订阅目前暂停新增订阅服务，页面仍可正常访问并查看之前已订阅数据，待改造完成后会重新开放新增订阅。给您的使用带来的不便我们深表歉意，感谢您对放舱网的支持与理解。</p>
    </div>
    <el-button slot="reference" class="query-input_btn" size="small">添加跟踪(深圳)</el-button>
  </el-popover>
</template>

<script>
export default {
  name: 'SZLogisticsBtn'
}
</script>

<style lang="stylus" scoped>
.notice-content
  padding 10px
  p
    color #E6A23C
    font-size 14px
    line-height 1.8
    margin 5px 0
    font-weight bold
    text-align left

.query-input_btn{
    cursor: pointer;
  height: 4rem;
  font-size: 14px;
  color: #fff;
  text-align: center;
  border-radius: 0 4px 4px 0;
  background: #ff9807;
  border: none;
  padding:12px;
  font-weight: bold;
}

.notice-content_title{
    font-size 16px
    font-weight bold
    text-align center
}
i{
    font-size 20px
    margin-right 4px
    color #ff9807
}
</style>
